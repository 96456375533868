import { Injectable } from '@angular/core';
import { CanLoad, Router, UrlTree } from '@angular/router';
import { Observable,} from 'rxjs';
import { getToken } from '../stores/lookup/lookup.selectors';
import { Store, select } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad {
  private token: any;

  constructor(private router: Router,private store: Store) {
    this.store.pipe(select(getToken)).subscribe((token) => {
      if (token?.length) this.token = token;      
    });
  }

  canLoad(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!this.token?.length) {
      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }
}