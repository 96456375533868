<div class="layout-topbar">
    <a class="app-logo" routerLink="/">
        <img src="assets/images/logo.svg">
    </a>
    
    <button #menubutton class="topbar-menubutton p-link" type="button" (click)="onMenuButtonClick()">
        <span></span>
    </button>
    
    <div class="topbar-profile">
        <button class="topbar-profile-button p-link" type="button" pStyleClass="@next" enterFromClass = "hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
            <img alt="avatar" src="assets/images/avatar.png">
            <span class="profile-details">
                <span class="profile-name">{{user?.username || ''}}</span>
                <span class="profile-job">{{ userRole }}</span>
            </span>
            <i class="pi pi-angle-down"></i>
        </button>
        <ul class="list-none p-3 m-0 border-round shadow-2 hidden absolute surface-overlay origin-top w-full sm:w-12rem mt-2 right-0 top-auto">
            <li>
                <a (click)="openProfile()" pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                    <i class="pi pi-user mr-3"></i>
                    <span>Profile</span>
                </a>
                <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                    <i class="pi pi-inbox mr-3"></i>
                    <span>Inbox</span>
                </a>
                <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                    <i class="pi pi-cog mr-3"></i>
                    <span>Settings</span>
                </a>
                <a pRipple (click)="logout()" class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                    <i class="pi pi-power-off mr-3"></i>
                    <span >Sign Out</span>
                </a>
            </li>
        </ul>
    </div>
</div>
