import { createReducer, on, Action } from '@ngrx/store';
import { SetToken, SetUser, SetDomain, SetAcl, SetSuperAdmin } from '@app/shared/stores/lookup/lookup.actions';
import { ILookupState, initialLookupState } from '@app/shared/stores/lookup/lookup.state';

const createLookupReducer = createReducer(
    initialLookupState,

    on(SetUser, (state, { user }) => ({ ...state, user })),
    on(SetToken, (state, { token }) => ({ ...state, token })),
    on(SetDomain, (state, { domain }) => ({ ...state, domain })),
    on(SetAcl, (state, { acls }) => ({ ...state, acls })),
    on(SetSuperAdmin, (state, { isSuperAdmin }) => ({ ...state, isSuperAdmin })),
);

export function lookupReducer(state: ILookupState = initialLookupState, action: Action) {
    if (action.type === '@ngrx/store/init') {
        const appState = localStorage.getItem('appState');
        if (appState) {
            state = JSON.parse(appState);
        }
    } else if (action.type === "[Lookup] Logout") {
        state = { ...initialLookupState };
    }

    localStorage.setItem('appState', JSON.stringify(state));
    return createLookupReducer(state, action);
}
