import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@app/environment';
import { SetAcl, SetDomain, SetSuperAdmin, SetToken, SetUser } from '@app/shared/stores/lookup/lookup.actions';
import { Store, select } from '@ngrx/store';
import { ILookupState } from '@app/shared/stores/lookup/lookup.state';
import { getToken } from '@app/shared/stores/lookup/lookup.selectors';
import { IDomain } from '@app/shared/models/domain';
import md5 from 'md5-ts';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiBaseUrl: string = environment.apiBaseUrl;
  token: string = '';

  constructor(private http: HttpClient, private store: Store<ILookupState>) {
      this.store.pipe(select(getToken)).subscribe((token) => {
        this.token = token?.length ? token.split(' ')[1]: '';      
      });
  }

  login(username: string, password: string, selectedDomain: IDomain): Observable<boolean> {
    return this.http.post<any>(`${this.apiBaseUrl}/login`, {
      username, password: md5(password)
    },
    {
      observe: 'response',
    }).pipe(map((response: any) => {
      const headers: HttpHeaders = response.headers;
      this.store.dispatch(SetToken({ token: headers.get('authorization') }));
      this.store.dispatch(SetUser({ user: response.body }));
      this.store.dispatch(SetDomain({ domain: selectedDomain }));
      this.store.dispatch(SetSuperAdmin({ isSuperAdmin: response.body.user_type === "sadmin" }));
      this.store.dispatch(SetAcl({
        acls: response.body.profiles.length && response.body.profiles[0].acl.length 
          ? response.body.profiles[0].acl 
          : []
      }));
      return true;
    }));
  }
  
  logout(): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}/logout`, {access_token: this.token});
  }
}
