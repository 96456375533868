import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
    {
        path: '', component: AppLayoutComponent, canLoad: [AuthGuard],
        children: [
            { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
            { path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'user',  loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule) },
            { path: 'address',  loadChildren: () => import('./modules/addressbook/addressbook.module').then(m => m.AddressBookModule) },
            { path: 'subscriber',  loadChildren: () => import('./modules/subscriber/subscriber.module').then(m => m.SubscriberModule) },
            { path: 'queue',  loadChildren: () => import('./modules/queue/queue.module').then(m => m.QueueModule) },
            { path: 'agent',  loadChildren: () => import('./modules/agents/agent.module').then(m => m.AgentModule) }
        ]
    },
    { path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
    { path: '**', redirectTo: '/login' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
