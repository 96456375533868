import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ILookupState } from '@app/shared/stores/lookup/lookup.state';

export const featureSelector = createFeatureSelector<ILookupState>('lookup');

export const getUser = createSelector(featureSelector, (state) => state.user);
export const getDomain = createSelector(featureSelector, (state) => state.domain);
export const getToken = createSelector(featureSelector, (state) => state.token);
export const getAcls = createSelector(featureSelector, (state) => state.acls);
export const getSuperAdmin = createSelector(featureSelector, (state) => state.isSuperAdmin);
