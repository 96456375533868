import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { getDomain, getToken } from '@app/shared/stores/lookup/lookup.selectors';
import { Store, select } from '@ngrx/store';
import { ILookupState } from '@app/shared/stores/lookup/lookup.state';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private token: string;
  selectedDomain: string = '';
  tokenWhiteListUrls = [];
  domainWhiteListUrls = ['/coipx/capi/v1/users'];

  constructor(private router: Router, private store: Store<ILookupState>) {
    this.store.pipe(select(getToken)).subscribe((token) => {
      this.token = token?.length ? token : '';
    });

    this.store.pipe(select(getDomain)).subscribe((domain) => {
      this.selectedDomain = domain?.domain?.length ? domain.domain : '';
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes('.json') && this.token?.length) {
      let headers = {};

      if (!this.tokenWhiteListUrls.some(url => request.url.includes(url))) {
        headers = { ...headers, Authorization: this.token };
      }

      if (!this.domainWhiteListUrls.some(url => request.url.includes(url)) && this.selectedDomain?.length) {
        headers = { ...headers, 'X-Request-Domain': this.selectedDomain };
      }

      if (Object.keys(headers)?.length) {
        request = request.clone({ setHeaders: headers });
      }
    }

    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        this.router.navigate(['/login']);
      }
      return throwError(error);
    }));
  }
}
