import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { Router } from '@angular/router';
import { AuthService } from '@app/shared/services/api/auth.service';
import { Store, select } from '@ngrx/store';
import { setLogout } from '@app/shared/stores/lookup/lookup.actions';
import { getUser } from '@app/shared/stores/lookup/lookup.selectors';
import { User } from '@app/shared/models/user';
import { LoaderService } from '@app/shared/services/loader/loader.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss']
})
export class AppTopBarComponent implements OnInit {
    @ViewChild('searchinput') searchInput!: ElementRef;
    @ViewChild('menubutton') menuButton!: ElementRef;
    searchActive: boolean = false;
    menu: MenuItem[] = [];
    user: User;
    userRole: string = '';

    constructor(public layoutService: LayoutService, private route: Router, private authService: AuthService,
        private messageService: MessageService, private store: Store, private loaderService: LoaderService) { }

        ngOnInit(): void {
            this.store.pipe(select(getUser)).subscribe((user: User) => {
                    this.user = user?.profiles?.length ? user : null;
                   this.userRole = user?.profiles?.length && user.profiles[0].name?.length ? user.profiles[0].name : '';
              });
        }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    activateSearch() {
        this.searchActive = true;
        setTimeout(() => {
            this.searchInput.nativeElement.focus();
        }, 100);
    }

    deactivateSearch() {
        this.searchActive = false;
    }

    removeTab(event: MouseEvent, item: MenuItem, index: number) {
        this.layoutService.onTabClose(item, index);
        event.preventDefault();
    }

    get layoutTheme(): string {
        return this.layoutService.config().layoutTheme;
    }

    get colorScheme(): string {
        return this.layoutService.config().colorScheme;
    }

    get tabs(): MenuItem[] {
        return this.layoutService.tabs;
    }

    logout = () => {
        this.loaderService.show();
        this.authService.logout().subscribe((res: any) => {
            this.loaderService.hide();
            this.store.dispatch(setLogout());
            this.redirectToLogin();
        }, err => {
                this.loaderService.hide();
                this.store.dispatch(setLogout());
                this.redirectToLogin();
            }
        );
    };

    redirectToLogin() {
        this.route.navigate(['/login']);
    }

    openProfile(){
        this.route.navigate(['/user/form'], { queryParams: {id: this.user.id}});
    }
}
