import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';
import { RequestInterceptor } from './shared/interceptors/request.interceptor';
import { lookupReducer } from '@app/shared/stores/lookup/lookup.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@app/environment';
import { MessageService } from "primeng/api";
import { BrowserModule } from '@angular/platform-browser';
import { ToastModule } from "primeng/toast";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

@NgModule({
    imports: [
        AppLayoutModule,
        AppRoutingModule,
        BrowserModule,
        ToastModule,
        RecaptchaV3Module,
        StoreModule.forRoot({ lookup: lookupReducer }),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.recaptchakey
        },
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
        MessageService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
