import { Component } from '@angular/core';
import { LoaderService } from '@app/shared/services/loader/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './app.loader.component.html',
  styleUrls: ['./app.loader.component.scss']
})

export class LoaderComponent {
  isShowLoader: boolean = false;

  constructor(private loaderService: LoaderService) {
    this.loaderService.loaderState.subscribe(isShowLoader => {
      this.isShowLoader = isShowLoader;
    });
  }
}
