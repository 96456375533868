import { IDomain } from "@app/shared/models/domain";
import { User } from "@app/shared/models/user";

export interface ILookupState {
    user: User;
    domain: IDomain;
    token: string;
    acls: any[];
    isSuperAdmin:boolean;
}

export const initialLookupState: ILookupState = {
    user: undefined,
    domain: undefined,
    token: '',
    acls: [],
    isSuperAdmin:false
};
